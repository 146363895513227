<template>
  <div>
    <div class="navheader_box">
      <brows></brows>
      <div class="elasticity edition_wdth navbox">
        <!-- logo -->
        <div class="logo_content">
          <div class="logo_div" @click="gohomebtn">
            <img
              v-if="siteinfodata"
              :src="getImgSrc(siteinfodata.logo)"
              alt=""
            />
          </div>
        </div>
        <!-- 导航 -->
        <div class="elasticity navlist_box">
          <li
            v-for="(item, index) in getnavlist"
            :key="index"
            v-if="item.status"
            @click.stop="Jumpchangbtn(item)"
          >
            <div
              class="item_div"
              :class="activeMenu(item) ? 'activecolor' : ''"
            >
              {{ item.title }}
            </div>
            <img
              class="navactive_div"
              v-if="activeMenu(item)"
              src="@/assets/home/nav_active.png"
              alt=""
            />
            <div class="suspension_box" v-if="item.children[0].meta">
              <div
                class="secondary_item"
                v-for="(nav, navindex) in item.children"
                :key="navindex"
                v-if="nav.meta && nav.meta.status"
                @click.stop="secondarybtn(nav)"
                :class="
                  nav.name == secondaryActive ? 'secondaryactive_div' : ''
                "
              >
                {{ nav.meta.title }}
              </div>
            </div>
          </li>
        </div>
        <!--移动端个人中心按钮 -->
        <div class="elasticity move_box_content">
          <div class="move_user_box" @click="gomemberbtn">
            <i class="el-icon-s-custom"></i>
          </div>
          <div class="move_nav_list" @click="movenavbtn">
            <li></li>
            <li></li>
            <li></li>
          </div>
        </div>
      </div>
    </div>
    <div class="seat_box"></div>
    <el-drawer
      :visible.sync="drawer"
      :direction="direction"
      :show-close="false"
      :with-header="false"
    >
      <el-collapse class="drawer_box" accordion>
        <el-collapse-item
          :title="item.title || ''"
          :name="index"
          v-for="(item, index) in getnavlist"
          :key="index"
          v-if="item.title && item.status"
          :class="activeMenu(item) ? 'secondaryactive_tite' : ''"
        >
          <div
            class="drawer_li"
            v-for="(nav, indexs) in item.children"
            :key="indexs"
            @click.stop="linkpathbtn(nav)"
            v-if="nav.meta && nav.meta.status"
            :class="nav.name == secondaryActive ? 'secondaryactive_div' : ''"
          >
            {{ nav.meta.title }}
          </div>
          <div
            v-if="item.children.length <= 1 && item.redirect == 'homeview'"
            class="drawer_li"
            @click.stop="linkpathbtn(item.children[0])"
            :class="
              item.children[0].name == secondaryActive
                ? 'secondaryactive_div'
                : ''
            "
          >
            首页
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-drawer>
  </div>
</template>
<script>
import Brows from "./brows";
export default {
  components: {
    Brows,
  },
  data() {
    return {
      drawer: false,
      direction: "ttb",
      // navlistlist: [
      //   {
      //     title: "首页",
      //     navstate: false,
      //     name: "Homeview",
      //     path: "/homeview",
      //   },
      //   {
      //     title: "走进鹤立",
      //     navstate: false,
      //     name: "EnterHL",
      //     path: "/enterHL",
      //   },
      //   {
      //     title: "新闻中心",
      //     navstate: false,
      //     name: "News",
      //     path: "/news",
      //   },
      //   {
      //     title: "国内教育",
      //     navstate: false,
      //     name: "Domestic",
      //     path: "/domestic",
      //   },
      //   {
      //     title: "国际教育",
      //     navstate: false,
      //     name: "International",
      //     path: "/international",
      //   },
      //   {
      //     title: "网络学院",
      //     navstate: false,
      //     name: "Network",
      //     path: "/network",
      //   },
      //   {
      //     title: "项目合作",
      //     navstate: false,
      //     name: "Cooperate",
      //     path: "/cooperate",
      //   },
      //   {
      //     title: "联系我们",
      //     navstate: false,
      //     name: "Contact",
      //     path: "/contact",
      //   },
      // ],
    };
  },
  computed: {
    // 获取站点配置信息
    siteinfodata() {
      return this.$store.state.user.siteinfo;
    },

    getnavlist() {
      const route = this.$route;
      const { path, name } = route;
      let routerlist = this.$router.options.routes;
      let navlist = routerlist;
      return navlist;
    },
    secondaryActive() {
      // item.navstate
      const route = this.$route;
      const { path, name } = route;
      return name;
    },
  },
  created() {
    // this.activeMenu();
  },
  methods: {
    activeMenu(item) {
      const route = this.$route;
      let navstate = "";
      const { meta, name, path } = route;
      if (item.title) {
        if (path.indexOf(item.redirect) != -1) {
          navstate = true;
        } else {
          if (item.redirect == name) {
            navstate = true;
          } else {
            navstate = false;
          }
        }
      }
      return navstate;
      // this.navlistlist.filter((item, index) => {
      //   if (item.path.indexOf("/enterHL") != -1) {
      //     this.navlistlist[index].navstate = true;
      //   } else {
      //     this.navlistlist[index].navstate = false;
      //   }
      // });
    },
    // 点击logo去首页
    gohomebtn() {
      this.$router.push({
        name: "homeview",
      });
      this.drawer = false;
    },
    // 移动端路由跳转
    linkpathbtn(e) {
      this.$router.push({
        name: e.name,
      });
      this.drawer = !this.drawer;
    },
    // 路由跳转
    Jumpchangbtn(e) {
      this.$router.push({
        name: e.children[0].name,
      });
      window.scrollTo(0, 0);
    },
    // 二级跳转
    secondarybtn(e) {
      this.$router.push({
        name: e.name,
      });
      window.scrollTo(0, 0);
    },
    // 点击显示移动导航
    movenavbtn() {
      this.drawer = !this.drawer;
    },
    // 会员中心
    gomemberbtn() {
      this.drawer = false;
      if (this.$store.state.user.token) {
        this.$router.push({
          name: "member",
        });
      } else {
        //登录
        this.$router.push({
          name: "register",
        });
      }
    },
  },
};
</script>
<style lang="less">
.navheader_box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  z-index: 9999;
}
.navbox {
  background: #ffffff;
}
@media screen and (min-width: 750px) {
  .seat_box {
    height: 113px;
  }
  .navbox {
    height: 73px;
    // position: fixed;
    .logo_content {
      display: flex;
      align-items: center;
      .logo_div {
        width: 160px;
        height: 48px;
        margin-right: 10px;
        line-height: 0;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .enterprise_name {
        font-size: 26px;
        font-family: MiSans;
        font-weight: 400;
        color: #28306f;
      }
    }
    .navlist_box {
      width: 70%;

      li {
        position: relative;
        width: 13%;
        height: 73px;
        font-size: 15px;
        font-family: MiSans;
        font-weight: 400;
        color: #333333;
        list-style: none;
        text-align: center;
        line-height: 73px;
        cursor: pointer;
        .item_div {
          position: absolute;
          top: 0px;
          left: 0%;
          width: 100%;
          height: 100%;
          background: #ffffff;
          z-index: 1;
        }
        .navactive_div {
          position: absolute;
          bottom: 10px;
          left: 50%;
          width: 20px;
          height: 10px;
          margin-left: -10px;
          z-index: 9;
        }
        .activecolor {
          color: #28306f;
        }
        .suspension_box {
          display: none;
          position: absolute;
          top: 73px;
          left: 50%;
          width: 150px;
          margin-left: -75px;
          // height: 100px;
          background: #ffffff;
          padding: 0 30px;
          box-sizing: border-box;
          .secondary_item {
            line-height: 40px;
            border-bottom: 3px solid #ffffff;
          }
          .secondaryactive_div {
            color: #28306f;
            border-bottom: 3px solid #28306f;
          }
        }
      }
      li:hover > .suspension_box {
        animation: slide-down 0.5s ease;
        display: block;
      }
      @keyframes slide-down {
        from {
          top: 0px;
          // transform: translateY(-30%);
        }
        to {
          top: 73px;
          // transform: translateY(0%);
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
  .move_box_content {
    display: none;
  }
}
@media screen and (max-width: 750px) {
  .navlist_box {
    display: none;
  }
  .navbox {
    height: 1.5rem;
    // padding: 0 0.3rem;
    box-sizing: border-box;
  }
  .seat_box {
    height: 1.5rem;
  }
  .logo_div {
    width: 3rem;
    height: 0.9rem;
    line-height: 0;
  }

  .drawer_box {
    padding: 0 0.5rem;

    // .drawer_box_ul {
    //   // min-height: 50vh;
    //   li {
    //     font-size: 0.32rem;
    //     padding: 0.2rem 0.03rem;
    //     text-align: center;
    //   }
    // }
    .drawer_li {
      // width: 1.5rem;
      padding: 0.2rem 0;
      font-size: 0.32rem !important;
      font-size: 0.15rem;
      font-family: MiSans;
      font-weight: 400;
      color: #333333;
      // text-align: center;
    }
    .el-collapse-item__header {
      font-size: 0.15rem;
      font-family: MiSans;
      font-weight: 400;
      color: #333333;
    }
    .secondaryactive_tite {
      .el-collapse-item__header {
        color: #28306f;
      }
    }
    .secondaryactive_div {
      color: #28306f;
      border-bottom: 0.04rem solid #28306f;
    }
  }
  .el-collapse-item__header {
    font-size: 0.32rem !important;
  }
  .el-collapse-item__content {
    padding: 0 0.3rem !important;
  }
  .el-drawer__wrapper {
    padding: 1.5rem 0 0 0;
  }
  .el-drawer__wrapper > .el-drawer__container > .el-drawer {
    height: 45% !important;
    min-height: 45% !important;
  }
  .move_box_content {
    display: flex;
    .move_user_box {
      color: #cccccc;
      font-size: 0.65rem;
      margin-right: 0.2rem;
    }
    .move_nav_list {
      width: 0.6rem;
      height: 0.6rem;
      background: #cccccc;
      padding: 0.03rem 0.05rem;
      border-radius: 0.05rem;
      box-sizing: border-box;

      li {
        width: 100%;
        height: 0.05rem;
        background: #ffffff;
        line-height: 0rem;
        margin: 0.09rem 0;
        border-radius: 0.05rem;
      }
    }
  }
}
</style>