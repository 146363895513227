import { getBanner } from '@/api/common'
// import { resetRouter } from '@/router'

const getDefaultState = () => {
    return {
        bannerlist: [], //

    }
}

const state = getDefaultState()

const mutations = {

    SET_BANNER: (state, info) => {
        state.bannerlist = info
    },

}

const actions = {
    // 获取banner
    getBanner({ commit }, type) {
        return new Promise((resolve, reject) => {
            getBanner({ type: type }).then(response => {
                const { data } = response
                commit('SET_BANNER', data.banner)
                resolve(response)
            }).catch(error => {
                // reject(error)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}