import request from '@/utils/request'

// 公共Banner
export function getBanner(data) {
    return request({
        url: '/api/getBanner',
        method: 'post',
        data
    })
}

// 公共详情
export function getRowInfo(data) {
    return request({
        url: '/api/RowInfo',
        method: 'post',
        data
    })
}

// 提交咨询
export function postConsult(data) {
    return request({
        url: '/api/Consult',
        method: 'post',
        data
    })
}
// 校验是否配置
export function isrundata(data) {
    return request({
        url: '/api/s_run',
        method: 'post',
        data
    })
}