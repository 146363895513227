import { login, register, notloginPass, getSitedatainfo, yesloginPass, loginout } from '@/api/user'
import { getToken, setToken, setUserinfo, getUserinfo, getSiteinfo, setSiteinfo, removeToken, removeUser } from '@/utils/auth'
// import { resetRouter } from '@/router'

const getDefaultState = () => {
    return {
        token: getToken(),
        userInfo: getUserinfo(),
        siteinfo: getSiteinfo(), //站点信息
        name: '',
        avatar: ''
    }
}

const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
    SET_USERINFO: (state, info) => {
        state.userInfo = info
    },
    SET_SITE: (state, info) => {
        state.siteinfo = info
    },

}

const actions = {
    // 登录
    login({ commit }, userInfo) {
        // const { username, password } = userInfo
        // login({ username: username, password: password }).then(response => {
        //     const { data } = response
        //     commit('SET_TOKEN', data.token)
        //     setToken(data.token)
        //         // resolve()
        // }).catch(error => {
        //         // reject(error)
        // })
        return new Promise((resolve, reject) => {
            login(userInfo).then(response => {
                const { user, token } = response
                commit('SET_TOKEN', token)
                commit('SET_USERINFO', user)
                setToken(token)
                setUserinfo(user)
                resolve(response)
            }).catch(error => {
                // reject(error)
            })
        })
    },
    // 注册
    register({ commit }, userInfo) {
        return new Promise((resolve, reject) => {
            register(userInfo).then(response => {
                const { user, token } = response
                commit('SET_TOKEN', token)
                commit('SET_USERINFO', user)
                setToken(token)
                setUserinfo(user)
                resolve(response)
            }).catch(error => {
                // reject(error)
            })
        })

    },
    // 未登录修改密码
    notloginPass({ commit }, userInfo) {
        return new Promise((resolve, reject) => {
            notloginPass(userInfo).then(response => {
                const { user, token } = response
                commit('SET_TOKEN', token)
                commit('SET_USERINFO', user)
                setToken(token)
                setUserinfo(user)
                resolve(response)
            }).catch(error => {
                // reject(error)
            })
        })
    },
    // 已登录修改密码
    yesloginPass({ commit }, userInfo) {
        return new Promise((resolve, reject) => {
            yesloginPass(userInfo).then(response => {
                // const { user, token } = response
                // commit('SET_TOKEN', token)
                // commit('SET_USERINFO', user)
                // setToken(token)
                // setUserinfo(user)
                resolve(response)
            }).catch(error => {
                // reject(error)
            })
        })
    },
    // 获取站点信息
    getSitedatainfo({ commit }) {
        return new Promise((resolve, reject) => {
            getSitedatainfo().then(response => {
                const { data } = response
                commit('SET_SITE', data.site)
                setSiteinfo(data.site)
            }).catch(error => {
                // reject(error)
            })
        })
    },
    // 获取用户信息
    // get user info
    getInfo({ commit, state }) {
        return new Promise((resolve, reject) => {
            getInfo(state.token).then(response => {
                const { data } = response
                if (!data) {
                    return reject('Verification failed, please Login again.')
                }
                const { name, avatar } = data
                commit('SET_NAME', name)
                commit('SET_AVATAR', avatar)
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 退出登录
    loginout({ commit, state }) {
        return new Promise((resolve, reject) => {
            loginout(state.token).then(() => {
                removeToken() // must remove  token  first
                removeUser()
                    // resetRouter()
                commit('RESET_STATE')
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 删除token
    // remove token
    resetToken({ commit }) {
        return new Promise(resolve => {
            removeToken() // must remove  token  first
            commit('RESET_STATE')
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}