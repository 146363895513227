<template>
  <div>
    <!-- <brows></brows> -->
    <navheader></navheader>
    <section class="app-main">
      <transition name="fade-transform" mode="out-in">
        <router-view :key="key" />
      </transition>
    </section>
    <Navfooter v-if="activeMenu"></Navfooter>
  </div>
</template>

<script>
import { Brows, Navheader, Navfooter } from "./components";
// import ResizeMixin from './mixin/ResizeHandler'

export default {
  name: "Layout",
  components: {
    Brows,
    Navheader,
    Navfooter,
  },
  // mixins: [ResizeMixin],
  computed: {
    key() {
      return this.$route.path;
    },
    activeMenu() {
      const route = this.$route;
      let navstate = "";
      const { meta, name, path } = route;
      if (path.indexOf("register") != -1) {
        if (
          path.indexOf("articledetail") != -1 ||
          path.indexOf("studydetail") != -1
        ) {
          navstate = true;
        } else {
          navstate = false;
        }
      } else {
        navstate = true;
      }
      return navstate;
    },
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    device() {
      return this.$store.state.app.device;
    },
    fixedHeader() {
      return this.$store.state.settings.fixedHeader;
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile",
      };
    },
  },
  created() {},
  methods: {
    handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", { withoutAnimation: false });
    },
  },
};
</script>
<style lang="less" scoped>
@media screen and (max-width: 750px) {
  .app-main {
    // width: 750px;
    overflow: hidden;
  }
}
</style>


