import request from '@/utils/request'

export function login(data) {
    return request({
        url: '/api/login',
        method: 'post',
        data
    })
}
export function register(data) {
    return request({
        url: '/api/register',
        method: 'post',
        data
    })
}
// 验证码
export function getverifycode(data) {
    return request({
        url: '/api/sendSms',
        method: 'post',
        data
    })
}
// 未登录修改密码
export function notloginPass(data) {
    return request({
        url: '/api/noLoginModifyPass',
        method: 'post',
        data
    })
}
// 未登录修改密码
export function yesloginPass(data) {
    return request({
        url: '/api/modifyPass',
        method: 'post',
        data
    })
}

// 获取站点基本信息
export function getSitedatainfo() {
    return request({
        url: '/api/getConfig',
        method: 'get',
    })
}
// 联系我们
export function getContactdata() {
    return request({
        url: '/api/Contact',
        method: 'get',
    })
}
// 获取订单信息
export function getorderlist(data) {
    return request({
        url: '/api/orderList',
        method: 'post',
        data
    })
}
// 获取收藏信息
export function getcollectlist() {
    return request({
        url: '/api/collectList',
        method: 'get',
    })
}
// 退出登录
export function loginout(data) {
    return request({
        url: '/api/logout',
        method: 'post',
        data
    })
}