<template>
  <div>
    <div class="brows_box">
      <div class="elasticity edition_wdth brows_div">
        <div>{{ siteinfodata.welcome_msg }}</div>
        <div class="elasticity member_content">
          <div class="" v-if="!getuserinfo">
            <span @click="gologinbtn">会员登录</span>/<span
              @click="goregisterbtn"
              >注册</span
            >
          </div>
          <div class="user_yes_login" v-if="getuserinfo">
            {{ getuserinfo.username }}
            <div class="exitbox_btn" @click="exitbtn">退出登录</div>
          </div>
          <div class="login_btnbox" @click="gomemberbtn">会员中心</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "brows",
  computed: {
    // 站点信息
    siteinfodata() {
      return this.$store.state.user.siteinfo;
    },
    // 用户信息
    getuserinfo() {
      return this.$store.state.user.userInfo;
    },
  },
  created() {
    // {{ $store.state.userInfo.username }}
    this.getSitedata();
  },
  methods: {
    // 站点信息
    getSitedata() {
      this.$store.dispatch("user/getSitedatainfo");
    },
    // 去登录
    gologinbtn() {
      this.$router.push({
        name: "login",
      });
    },
    // 去注册
    goregisterbtn() {
      this.$router.push({
        name: "register",
      });
    },
    // 退出登录
    exitbtn() {
      this.$store.dispatch("user/loginout").then((res) => {
        this.$router.push({
          name: "homeview",
        });
      });
    },
    // 会员中心
    gomemberbtn() {
      if (this.$store.state.user.token) {
        this.$router.push({
          name: "member",
        });
      } else {
        //登录
        this.$router.push({
          name: "register",
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/styles/publicstyle.css";
@import "../styles/navstyle.css";
@media screen and (min-width: 750px) {
  .brows_box {
    background: #f5f5f5;

    font-size: 14px;
    font-family: MiSans-Norm;
    font-weight: 400;
    color: #888888;
    .brows_div {
      height: 40px;
      .member_content {
        font-size: 14px;
        font-family: MiSans;
        font-weight: 400;
        color: #555555;
        cursor: pointer;
        .login_btnbox {
          margin-left: 50px;
          cursor: pointer;
        }
      }
      .user_yes_login {
        position: relative;
        .exitbox_btn {
          display: none;
          width: 70px;
          height: 40px;
          position: absolute;
          top: 20px;
          left: 50%;
          margin-left: -35px;
          background: #ffffff;
          z-index: 10;
          // padding: 10px 20px;
          box-sizing: border-box;
          white-space: nowrap;
          font-size: 14px;
          font-family: MiSans;
          font-weight: 400;
          line-height: 40px;
          text-align: center;
          box-shadow: 0 10px 10px 1px #eeeeee;
          cursor: pointer;
        }
      }
      .user_yes_login:hover .exitbox_btn {
        display: block;
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .brows_box {
    display: none;
  }
}
</style>