import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 在 main.js 中引入
import '@/assets/fontFamily/font.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
import { Swipe, SwipeItem } from 'vant';
import 'vant/lib/index.css';
Vue.use(Swipe);
Vue.use(SwipeItem);
// import "swiper/swiper.min.css"


Vue.config.productionTip = false

Vue.prototype.getImgSrc = function(img) {
    return sessionStorage.getItem("baseURL") + img;
}
Vue.prototype.renderContent = function(html) {
    const regex = /<img([^>]*?)src="([^"]*?)"([^>]*?)>/gi;
    if (html) {
        return html.replace(regex, `<img$1src="${sessionStorage.getItem("baseURL")}$2"$3>`);
    } else {
        return html
    }

}

new Vue({
        router,
        store,
        render: h => h(App),
        created() {
            // 实例创建完成后被立即调用rem换算方法,解决内容闪现问题
            if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
                //移动端使用
                setRem();
                window.addEventListener('resize', setRem); //浏览器窗口大小改变时调用rem换算方法
            } else {
                //pc端使用
                setRemPC();
                window.addEventListener('resize', setRemPC); //浏览器窗口大小改变时调用rem换算方法
            }
        }
    }).$mount('#app')
    //pc端
function setRemPC() {
    var whdef = 50 / 1400; // 表示1920的设计图,使用100PX的默认值
    var bodyWidth = document.body.clientWidth; // 当前窗口的宽度
    var rem = bodyWidth * whdef; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
    document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
}
// 手机端
function setRem() {
    var whdef = 100 / 750; // 表示750的设计图,使用100PX的默认值
    var bodyWidth = document.body.clientWidth; // 当前窗口的宽度
    var rem = bodyWidth * whdef; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
    document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
}