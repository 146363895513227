import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import banner from './modules/banner'
import getters from './getters'
Vue.use(Vuex)


const store = new Vuex.Store({
    modules: {
        user,
        banner
    },
    getters
})
export default store