import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
// create an axios instance
let baseURL = " "
sessionStorage.setItem('baseURL','https://www.helijiaoyu.org');
// let YQurl = "https://heli.new.hlidc.cn/s/#/home" //园区地址
let YQurl = "https://www.helijiaoyu.org/s/#/home" //园区地址
sessionStorage.setItem('YQurl', YQurl);
const service = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    baseURL: baseURL, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(
        config => {
            if (store.getters.token) {
                config.headers.token = getToken()
            }
            return config
        },
        error => {
            // console.log(error) // for debug
            return Promise.reject(error)
        }
    )
    // response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
            // if the custom code is not 20000, it is judged as an error.
        if (res.code !== 1) {
            // Message({
            //     message: res.message || 'Error',
            //     type: 'error',
            //     duration: 5 * 1000
            // })

            // 403:非法的token; 50012:其他客户端登录了;  401:Token 过期了;
            if (res.code === 403 || res.code === 50012 || res.code === 401) {
                MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
                    confirmButtonText: '重新登录',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    // console.log("失败")
                    // store.dispatch('FedLogOut').then(() => {
                    //     location.reload() // 为了重新实例化vue-router对象 避免bug
                    // })
                })
            }
            // return res
            Message({
                    message: res.msg,
                    type: 'error',
                    duration: 5 * 500,
                    offset: 140,
                })
                // return Promise.reject(res)
            return res
        } else {
            return res
        }
    },
    error => {
        // console.log('err' + error) // for debug
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 500,

            offset: 140,
        })
        return Promise.reject(error)
    }
)

export default service