import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import Layout from '@/layout'
import { getrouterList } from "@/api/routelist"
// import Homeview from "@/views/homeview/index"
/*
let routes = [{
        path: '/homeview',
        component: Layout,
        redirect: 'homeview', //首页
        title: "首页",
        children: [{
            path: '/',
            name: 'Homeview',
            component: () =>
                import ('../views/homeview/index'),
        }]
    },
    {
        path: '/enterHL',
        component: Layout, //走进鹤立
        redirect: 'enterHL',
        title: "走进鹤立",
        children: [{
            path: '/enterHL',
            name: 'EnterHL',
            component: () =>
                import ('../views/enterHL/index.vue'),
            meta: { title: '鹤立概况', status: '1' },
        }, {
            path: 'culture',
            name: 'Culture',
            component: () =>
                import ('../views/enterHL/culture.vue'),
            meta: { title: '鹤立文化', status: '1' }
        }, {
            path: 'principal',
            name: 'Principal',
            component: () =>
                import ('../views/enterHL/principal.vue'),
            meta: { title: '校长简介', status: '1' }
        }, {
            path: 'feature',
            name: 'Feature',
            component: () =>
                import ('../views/enterHL/feature.vue'),
            meta: { title: '特色教育', status: '1' }
        }, {
            path: 'develop',
            name: 'Develop',
            component: () =>
                import ('../views/enterHL/develop.vue'),
            meta: { title: '发展历程', status: '1' }
        }, {
            path: 'fruit',
            name: 'Fruit',
            component: () =>
                import ('../views/enterHL/fruit.vue'),
            meta: { title: '发展历程', status: '1' }
        }, {
            path: 'idea',
            name: 'Idea',
            component: () =>
                import ('../views/enterHL/idea.vue'),
            meta: { title: '发展历程', status: '1' }
        }]
    },
    {
        path: '/news',
        component: Layout,
        redirect: 'news', //新闻中心
        title: "新闻中心",
        children: [{
            path: 'index',
            name: 'News',
            component: () =>
                import ('../views/news/index'),
            meta: { title: '鹤立动态', status: '1' }
        }, {
            path: 'industry',
            name: 'Industry',
            component: () =>
                import ('../views/news/industry.vue'),
            meta: { title: '行业新闻', status: '1' }
        }, {
            path: 'articledetail',
            name: 'articledetail',
            component: () =>
                import ('../views/news/articledetail.vue'),
            meta: { title: '新闻详情', status: '0' }
        }]
    },
    {
        path: '/domestic',
        component: Layout,
        redirect: 'domestic', //国内教育
        title: "国内教育",
        children: [{
            path: 'index',
            name: 'Domestic',
            component: () =>
                import ('../views/domestic/index'),
            meta: { title: '国内教育', status: '1' }

        }, {
            path: 'campus',
            name: 'Campus', //校园展示
            component: () =>
                import ('../views/domestic/campus.vue'),
            meta: { title: '校园展示', status: '1' }
        }, {
            path: 'resource',
            name: 'Resource', //办学资源
            component: () =>
                import ('../views/domestic/resource.vue'),
            meta: { title: '办学资源', status: '1' }
        }, {
            path: 'study',
            name: 'study, //国内研学营
            component: () =>
                import ('../views/domestic/study.vue'),
            meta: { title: '国内研学营', status: '1' }
        }]
    },
    {
        path: '/international',
        component: Layout,
        redirect: 'international', //国际教育
        title: "国际教育",
        children: [{
            path: 'index',
            name: 'International',
            component: () =>
                import ('../views/international/index'),
            meta: { title: '国际教育', status: '1' }
        }, {
            path: 'showcampus',
            name: 'Showcampus',
            component: () =>
                import ('../views/international/showcampus.vue'),
            meta: { title: ' 校园展示 ', status: '1' }
        }, {
            path: 'natural',
            name: 'Natural',
            component: () =>
                import ('../views/international/natural.vue'),
            meta: { title: ' 国际资源 ', status: '1' }
        }, {
            path: 'intabroad',
            name: 'Intabroad',
            component: () =>
                import ('../views/international/intabroad.vue'),
            meta: { title: ' 出国留学 ', status: '1' }
        }, {
            path: 'intstudy',
            name: 'Intstudy',
            component: () =>
                import ('../views/international/intstudy.vue'),
            meta: { title: ' 国际研学 ', status: '1' }
        }]
    },
    {
        path: '/network',
        component: Layout,
        redirect: 'network', //网络学院
        title: "网络学院",
        children: [{
            path: 'index',
            name: 'Network',
            component: () =>
                import ('../views/network/index'),
            meta: { title: '网络学院', status: '1' }
        }, {
            path: 'kindergarten',
            name: 'Kindergarten',
            component: () =>
                import ('../views/network/kindergarten.vue'),
            meta: { title: '学前教育', status: '1' }
        }, {
            path: 'primary',
            name: 'Primary',
            component: () =>
                import ('../views/network/primary.vue'),
            meta: { title: '中小学教育', status: '1' }
        }, {
            path: 'adult',
            name: 'Adult',
            component: () =>
                import ('../views/network/adult.vue'),
            meta: { title: '成人教育', status: '1' }
        }, {
            path: 'noteview',
            name: 'Noteview',
            component: () =>
                import ('../views/network/noteview.vue'),
            meta: { title: '教育随笔', status: '1' }
        }, {
            path: 'liveview',
            name: 'Liveview',
            component: () =>
                import ('../views/network/liveview.vue'),
            meta: { title: '直播课堂 ', status: '1' }
        }, {
            path: 'videodetail',
            name: 'Videodetail',
            component: () =>
                import ('../views/network/videodetail.vue'),
            meta: { title: '视频详情', status: '0' }
        }]
    },
    {
        path: '/cooperate',
        component: Layout,
        redirect: 'cooperate', //项目合作
        title: "项目合作",
        children: [{
            path: 'index',
            name: 'Cooperate',
            component: () =>
                import ('../views/cooperate/index')
        }]
    },
    {
        path: '/contact',
        component: Layout,
        redirect: 'contact', //联系我们
        title: "联系我们",
        children: [{
            path: 'index',
            name: 'Contact',
            component: () =>
                import ('../views/contact/index')
        }]
    }, {
        path: '/register',
        component: Layout,
        redirect: 'register', //登录注册
        children: [{
            path: 'index',
            name: 'Register',
            component: () =>
                import ('../views/register/index'),
            meta: { title: '注册', status: '1' }
        }, {
            path: 'login',
            name: 'Login',
            component: () =>
                import ('../views/register/login.vue'),
            meta: { title: '登录', status: '1' }
        }, {
            path: 'setpassword',
            name: 'Setpassword',
            component: () =>
                import ('../views/register/setpassword.vue'),
            meta: { title: '修改密码', status: '1' }
        }, {
            path: 'studydetail',
            name: 'Studydetail', //国内研学营详情
            component: () =>
                import ('../views/domestic/studydetail.vue'),
            meta: { title: '国内研学营详情', status: '0' }
        }]
    }, {
        path: '/member',
        component: Layout,
        redirect: 'member', //会员中心
        children: [{
            path: 'member',
            name: 'Member', //我的课程
            component: () =>
                import ('../views/member/index'),
            meta: { title: '我的课程', status: '1' }
        }, {
            path: 'collect',
            name: 'Collect', //我的收藏
            component: () =>
                import ('../views/member/collect.vue'),
            meta: { title: '我的收藏', status: '1' }
        }, {
            path: 'information', //我的信息
            name: 'Information',
            component: () =>
                import ('../views/member/information.vue'),
            meta: { title: '我的信息', status: '1' }
        }, {
            path: 'payment',
            name: 'Payment', //在线支付
            component: () =>
                import ('../views/member/payment.vue'),
            meta: { title: '在线支付', status: '0' }
        }, {
            path: 'orders',
            name: 'Orders', //订单提交
            component: () =>
                import ('../views/member/orders.vue'),
            meta: { title: '订单提交', status: '0' }
        }, {
            path: 'complete',
            name: 'Complete', //支付完成
            component: () =>
                import ('../views/member/complete.vue'),
            meta: { title: '支付完成', status: '0' }
        }]
    }
]
*/

let routes = await getrouterList().then(res => res.data)
routes.forEach(element => {
    element.component = Layout
    element.children.forEach((item, index) => {
        if (index == 0) {
            item.component = resolve => require([`@/views/${element.redirect}`], resolve)
        } else {
            item.component = resolve => require([`@/views/${element.redirect}/${item.path}.vue`], resolve)
        }


    })
});
const router = new VueRouter({
        routes
    })
    //解决vue路由重复导航错误
    //获取原型对象上的push函数

const originalPush = VueRouter.prototype.push
    //修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

// const createRouter = () => new Router({
//     // mode: 'history', // require service support
//     scrollBehavior: () => ({ y: 0 }),
//     routes: constantRoutes
// })
// export function resetRouter() {
//     const newRouter = createRouter()
//     router.matcher = newRouter.matcher // reset router
// }
export default router