import Cookies from 'js-cookie'

const TokenKey = ''
const Userinfo = ''

export function getToken() {
    // return Cookies.get(TokenKey)
    return JSON.parse(sessionStorage.getItem('TokenKey'))
}

export function setToken(token) {
    // return Cookies.set(TokenKey, token)
    return sessionStorage.setItem('TokenKey', JSON.stringify(token));
}

export function removeToken() {
    // return Cookies.remove(TokenKey)
    return sessionStorage.removeItem('TokenKey');
}


export function getUserinfo() {
    // console.log("Cookies", Cookies.get(Userinfo))
    // return Cookies.get(Userinfo)
    return JSON.parse(sessionStorage.getItem('Userinfo'))
}
export function setUserinfo(info) {
    // return Cookies.set(Userinfo, info)
    return sessionStorage.setItem('Userinfo', JSON.stringify(info));
    // sessionStorage.setItem('token', res.data.data.token);
}
// 删除用户信息
export function removeUser() {
    // return Cookies.remove(TokenKey)
    return sessionStorage.removeItem('Userinfo');
}
// 获取站点信息
export function getSiteinfo() {
    return JSON.parse(sessionStorage.getItem('Siteinfo'))
}
export function setSiteinfo(info) {
    return sessionStorage.setItem('Siteinfo', JSON.stringify(info));
}